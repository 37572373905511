@if (form) {
    <form
        *transloco="let t; prefix: translationScope"
        [formGroup]="form"
        (ngSubmit)="submit($event)">
        <fieldset>
            <legend>
                <h4>{{ t('title.serviceTool') }}</h4>
            </legend>
            <div class="mb-6">
                <p
                    id="isWorkLimitedTo20HoursLabel"
                    #isWorkLimitedTo20HoursLabel>
                    {{ t('label.isWorkLimitedTo20Hours') }}
                </p>
                <div class="grid grid-cols-12 gap-x-6">
                    <big-ui-button-group
                        class="col-span-12 md:col-span-6 lg:col-span-4"
                        [isValid]="form.controls.isWorkLimitedTo20Hours.valid"
                        [isTouched]="form.controls.isWorkLimitedTo20Hours.touched"
                        [options]="isWorkLimitedTo20HoursOptions"
                        [showValidation]="hasBeenSubmitted"
                        radiogroupId="isWorkLimitedTo20HoursLabel"
                        [isRequired]="required"
                        formControlName="isWorkLimitedTo20Hours"
                        name="isWorkLimitedTo20Hours"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: isWorkLimitedTo20HoursLabel.textContent!,
                            valid: !hasBeenSubmitted || form.controls.isWorkLimitedTo20Hours.valid,
                        }" />
                </div>
                @if (hasBeenSubmitted) {
                    <big-ui-error
                        class="mt-2"
                        [errors]="form.controls.isWorkLimitedTo20Hours.errors" />
                }
            </div>
            <div class="mb-6">
                <p
                    id="workLimitedTo3Months"
                    #workLimitedTo3Months>
                    {{ t('label.workLimitedTo3Months') }}
                </p>
                <div class="grid grid-cols-12 gap-x-6">
                    <big-ui-button-group
                        class="col-span-12 md:col-span-6 lg:col-span-4"
                        [isValid]="form.controls.workLimitedTo3Months.valid"
                        [isTouched]="form.controls.workLimitedTo3Months.touched"
                        [options]="workLimitedTo3MonthsOptions"
                        [showValidation]="hasBeenSubmitted"
                        radiogroupId="workLimitedTo3Months"
                        [isRequired]="required"
                        formControlName="workLimitedTo3Months"
                        name="workLimitedTo3Months"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: workLimitedTo3Months.textContent!,
                            valid: !hasBeenSubmitted || form.controls.workLimitedTo3Months.valid,
                        }" />
                </div>
                @if (hasBeenSubmitted) {
                    <big-ui-error
                        class="mt-2"
                        [errors]="form.controls.workLimitedTo3Months.errors" />
                }
            </div>
            <div class="mb-6">
                <p
                    id="workDuringRecessLabel"
                    #workDuringRecessLabel>
                    {{ t('label.workDuringRecess') }}
                </p>
                <div class="grid grid-cols-12 gap-x-6">
                    <big-ui-button-group
                        class="col-span-12 md:col-span-6 lg:col-span-4"
                        [isValid]="form.controls.workDuringRecess.valid"
                        [isTouched]="form.controls.workDuringRecess.touched"
                        [options]="workDuringRecessOptions"
                        [showValidation]="hasBeenSubmitted"
                        radiogroupId="workDuringRecessLabel"
                        [isRequired]="required"
                        formControlName="workDuringRecess"
                        name="workDuringRecess"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: workDuringRecessLabel.textContent!,
                            valid: !hasBeenSubmitted || form.controls.workDuringRecess.valid,
                        }" />
                </div>
                @if (hasBeenSubmitted) {
                    <big-ui-error
                        class="mt-2"
                        [errors]="form.controls.workDuringRecess.errors" />
                }
            </div>
            <big-ui-button
                [buttonStyle]="'secondary'"
                class="block my-10 last:mb-0"
                (clicked)="submit($event)">
                {{ t('label.submit') }}
            </big-ui-button>
            @if (hasBeenSubmitted && hasToBeInsured === true) {
                <div class="py-10 border-gray-300 border-y-2">
                    <h4>{{ t('result.needsInsurance.title') }}</h4>
                    <p class="mb-0">{{ t('result.needsInsurance.description') }}</p>
                </div>
            }
            @if (hasBeenSubmitted && hasToBeInsured === false) {
                <div class="py-10 border-gray-300 border-y-2">
                    <h4>{{ t('result.insuranceFree.title') }}</h4>
                    <p class="mb-0">{{ t('result.insuranceFree.description') }}</p>
                </div>
            }
        </fieldset>
    </form>
}
