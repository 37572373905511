import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ServiceToolBaseComponent, ServiceToolFieldTrackingDirective } from '@big-direkt/service-tools/base';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiButtonGroupComponent, type ButtonGroupDataModel } from '@big-direkt/ui/button-group';
import { UiErrorComponent } from '@big-direkt/ui/error';
import { TranslocoDirective } from '@jsverse/transloco';
import { type StudentInsuranceCheckForm } from '../models/student-insurance-check-form.model';

@Component({
    selector: 'big-service-tool-student-insurance-check',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        ServiceToolFieldTrackingDirective,
        TranslocoDirective,
        UiButtonComponent,
        UiButtonGroupComponent,
        UiErrorComponent,
    ],
    templateUrl: './student-insurance-check.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentInsuranceCheckComponent extends ServiceToolBaseComponent<StudentInsuranceCheckForm> implements OnInit {
    private readonly yesLabel = 'stStudentInsuranceCheck.label.yes';
    private readonly noLabel = 'stStudentInsuranceCheck.label.no';

    public override translationScope = 'stStudentInsuranceCheck';

    public isWorkLimitedTo20HoursOptions: ButtonGroupDataModel<boolean>[] = [
        {
            value: true,
            label: this.yesLabel,
        },
        {
            value: false,
            label: this.noLabel,
        },
    ];
    public workLimitedTo3MonthsOptions: ButtonGroupDataModel<boolean>[] = [
        {
            value: true,
            label: this.yesLabel,
        },
        {
            value: false,
            label: this.noLabel,
        },
    ];
    public workDuringRecessOptions: ButtonGroupDataModel<boolean>[] = [
        {
            value: true,
            label: this.yesLabel,
        },
        {
            value: false,
            label: this.noLabel,
        },
    ];

    public required = true;
    public hasToBeInsured: boolean | undefined = undefined;

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            isWorkLimitedTo20Hours: [
                undefined as boolean | undefined,
                [
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    Validators.required,
                ],
            ],
            workLimitedTo3Months: [
                undefined as boolean | undefined,
                [
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    Validators.required,
                ],
            ],
            workDuringRecess: [
                undefined as boolean | undefined,
                [
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    Validators.required,
                ],
            ],
        });
    }

    public submit(event: Event): void {
        event.preventDefault();
        this.hasBeenSubmitted = true;
        this.trackStFormSubmitEvent();

        if (!this.form?.valid) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        if (this.form.controls.isWorkLimitedTo20Hours.value || this.form.controls.workLimitedTo3Months.value) {
            this.hasToBeInsured = false;

            return;
        }

        this.hasToBeInsured = this.form.controls.workDuringRecess.value ? false : true;
    }
}

export default StudentInsuranceCheckComponent;
